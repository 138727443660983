<template>
  <div class="menu">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      height= '80px'
    >
      <el-menu-item
        :index="index.toString()"
        v-for="(item, index) in menuList"
        :key="index"
        >{{ item.lebal }}</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>

export default {
  components: {},
  props:{
    active: {
      type: String,
      required:  "0",
    },
    menuList: {
      type: Array,
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  mounted() {
    this.activeIndex = this.active
  },
  methods: {
    handleSelect(key, keyPath) {
      if (keyPath[0] !== this.activeIndex) {
        this.$router.push({
          name: this.menuList[key].name,
          params: {
            activeIndex: keyPath[0],
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.el-menu-demo{
  width: 600px;
  height: 80px;
  box-sizing: border-box;
}
.el-menu-item{
  font-size: 16px;
}
.el-menu--horizontal>.el-menu-item{
  height: 80px;
  line-height: 80px;
}
.el-menu--horizontal>.el-menu-item.is-active{
    color: #409EFF;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
    color: #409EFF;
}
.el-menu--horizontal>.el-menu-item{
    color: #333;
}
</style>
