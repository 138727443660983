<template>
  <div class="no_wrap">
    <div class="no_pic">
      <div class="tooip">Oooops.... Something is wrong</div>
      <div class="main">404</div>
      <div class="anniu" @click="goHome">GO HOME</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
};
</script>

<style>
.no_wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  background-image: url(../assets/img/404.jpg);
  background-size: cover;
  background-position: top center;
}
.no_pic {
  margin: auto;
  width: 600px;
  text-align: center;
}
.tooip {
  font-size: 30px;
  color: #fff;
  letter-spacing: 2px;
  margin-top: 30px;
}
.main {
  font-size: 150px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 14px;
  margin: 16px 0 31px;
  font-family: "Montserrat", sans-serif;
}
.anniu {
  color: #fff;
  letter-spacing: 1px;
  background: #409EFF;
  padding: 12px 30px;
  border-radius: 20px;
  font-size: 15px;
  display: inline-block;
  cursor: pointer;
}
</style>