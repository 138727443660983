<template>
  <div class="block">
    <el-carousel arrow="never" :interval="5000" :height="bannerHeight + 'px'">
      <el-carousel-item>
        <img src="../assets/img/jiatuo.jpeg" />
      </el-carousel-item>
      <el-carousel-item>
        <img src="../assets/img/jiatuo2.jpeg" v-if="imgShow" />
      </el-carousel-item>
      <el-carousel-item>
        <img src="../assets/img/shounaxinpian.jpg" v-if="imgShow" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 图片父容器高度
      bannerHeight: 1000,
      // 浏览器宽度
      screenWidth: 0,
      imgShow: false,
    };
  },
  methods: {
    setSize: function () {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = (800 / 1920) * this.screenWidth;
    },
  },
  mounted() {
    const timer = setInterval(() => {
      this.imgShow = true;
    }, 1000);
    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer); // 此处的timer即 上文const的 timer
    });
    // 首次加载时,需要调用一次
    window.innerWidth >= 1440
      ? (this.screenWidth = window.innerWidth)
      : (this.screenWidth = 1440);
    this.setSize();
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      window.innerWidth >= 1440
        ? (this.screenWidth = window.innerWidth)
        : (this.screenWidth = 1440);
      this.setSize();
    };
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: inherit;
}
</style>