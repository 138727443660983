<template>
  <div class="news_wrap">
    <div class="section_wrap">
      <div class="section-title" @click="handleCommand">{{ title }}</div>
      <div>
        <i class="el-icon-right el-icon-right_24" @click="handleCommand"></i>
      </div>
    </div>
    <carouselCard/>
  </div>
</template>

<script>
import carouselCard from "../components/carouselCard.vue";
import * as data from "../utils/data.js";
export default {
  components: {
    carouselCard,
  },
  props: {
    title: {
      type: String,
      required: "新闻中心",
    },
  },
  data(){
    return {
      data: data,
    }
  },
  methods: {
    handleCommand() {
      if (this.title == "新闻中心") {
        this.$router.push({
          name: "News",
        });
      } else {
        this.$router.push({
          name: "en/News",
        });
      }
    },
  },
};
</script>

<style scoped>
</style>