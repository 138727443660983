export const menuList = [
    { lebal: '首页', name: 'Home' },
    { lebal: '产品中心', name: 'Products' },
    { lebal: '服务项目', name: 'Services' },
    { lebal: '新闻中心', name: 'News' },
    { lebal: '关于我们', name: 'About' },
    { lebal: '联系我们', name: 'Contact' },
]

export const menuListEN = [
    { lebal: 'Home', name: 'en/Home' },
    { lebal: 'Products', name: 'en/Products' },
    { lebal: 'Services', name: 'en/Services' },
    { lebal: 'News', name: 'en/News' },
    { lebal: 'About', name: 'en/About' },
    { lebal: 'Contact', name: 'en/Contact' },
]

export const mainObj = {
    'title': '我们是谁',
    'jianjie1': '深圳市嘉拓科技有限公司（J&T TECHNOLOGYLTD）是一家专业的电子元器件独立分销商，创始人已有15年以上电子行业从业经验，公司秉承品质至上服务客户的经营理念，致力于为客户提供高效、便捷、可靠的电子元器件供应服务。',
    'jianjie2': '公司依托原厂、代理以及OEM等渠道为客户提供全方位一站式的电子元件供应链解决方案，包括：降低物料成本、BOM清单配套、为客户搜寻世界各大品牌停产偏冷门、军品、紧缺物料等服务。',
    'jianjie3': "",
    'jianjie4': "",
}

export const mainObjEN = {
    'title': 'Who We Are',
    'jianjie1': "J&T ELECTRONICS LTD is a professional independent distributor of electronic components, the founder has more than 15 years experiences in the electronics industry.",
    'jianjie2': "Headquartered in Shenzhen, China, J&T has been developing rapidly since established in 2019, and has set up branch offices in Hongkong, Singapore and the USA. The annual revenue already exceeded $50 Million in 2021.",
    'jianjie3': "With a focus on quality, J&T ELECTRONICS LTD is committed to provide efficient, convenient and reliable services and products to customers. Our supplying partners are manufacturers, franchised distributors and OEM factories. ",
    'jianjie4': "Our company's aim is to provide comprehensive supply chain solutions to customers, and help customers to reduce material costs and source all types of electronics components including military semiconductors and obsolete parts.",
}

export const ServicesList = [
    { lebal: '现货供应', lebals: '缩短交期', icon: 'el-icon-cpu' },
    { lebal: '渠道优化', lebals: '降低成本', icon: 'el-icon-coin' },
    { lebal: 'BOM配单', lebals: '提高效率', icon: 'el-icon-data-line' },
    { lebal: '技术支持', lebals: '清除痛点', icon: 'el-icon-finished' },
    { lebal: '专业检测', lebals: '保驾护航', icon: 'el-icon-trophy' },
]

export const ServicesListEN = [
    { lebal: 'Plentiful available stock', lebals: '', icon: 'el-icon-cpu' },
    { lebal: 'High quality supplying sources', lebals: '', icon: 'el-icon-coin' },
    { lebal: 'One-stop ordering services', lebals: '', icon: 'el-icon-data-line' },
    { lebal: 'Professional technical support', lebals: '', icon: 'el-icon-finished' },
    { lebal: 'Specialized testing services', lebals: '', icon: 'el-icon-trophy' },
]

export const footerObj = {
    'jianjie': '办公地址：深圳市龙岗区坂田街道雅宝路1号星河WORLD B2401a',
    'title': '网页链接',
}

export const footerObjEN = {
    'jianjie': 'Xinghe WORLD B2401a, No. 1 Yabao Road, Bantian Street, Longgang District, Shenzhen City.',
    'title': 'Web Page Link',
}

export const animate = [
    { title: '现货供应 缩短交期', jianjie1: '1、两千多项自有库存，保障现货供应。', jianjie2: '2、支持常用物料长期备货，缩短交期。', jianjie3: '3、海外货源1-2周高效交货。' },
    { title: '渠道优化 降低成本', jianjie1: '1、专业采购团队为您提供最优质渠道服务。', jianjie2: '2、与20多家国际知名供货商达成战略合作。', jianjie3: '3、4,000多家活跃供应商，覆盖海内外全球渠道。' },
    { title: 'BOM配单 提高效率', jianjie1: '1、提供“一站式”采购服务，解决多点购货烦恼。', jianjie2: '2、物料配套率最高可实现90%，降低询价成本。', jianjie3: '3、24小时不间断运作，保证报价、供货反馈效率。' },
    { title: '技术支持 清除痛点', jianjie1: '1、提供售前、售后专业技术支持。', jianjie2: '2、查找技术资料文档，排疑解惑。', jianjie3: '3、协助寻找停产、断档器件的替代方案。' },
    { title: '专业检测 保驾护航', jianjie1: '1、支持第三方元器件检测服务。', jianjie2: '2、提供权威检测机构的专业检测报告。', jianjie3: '3、协助培训质检人员掌握元器件外观检测技巧和方法。' },
]

export const animateEN = [
    { title: 'Large available-to-sell-inventory to support short lead-time demand', jianjie1: '1.Large inventory selection, over 2,000 line items and expanding.', jianjie2: '2.Ordering popular items in advance to shorten the lead time.', jianjie3: '3.	Just-in-time deliveries so that you get what you need.' },
    { title: 'High quality supplying services help customers to reduce material costs', jianjie1: '1.Professional purchasing team provides the best supplying services.', jianjie2: '2.Cooperating with lots of international well-known distributors.', jianjie3: '3.Collaborating with a great number of global EMSs, CEMs , OEMs and design houses.' },
    { title: 'Efficiently source products according to customers’ BOM', jianjie1: '1.One-stop ordering service to reduces your Total Acquisition Cost.', jianjie2: '2.Almost 90% products from the BOM can be supplied at the one time to avoid the expensive paperwork and time involved in ordering from multiple sources.', jianjie3: '3.Dedicated team divided into specialized business units  to handle inquires and follow up goods delivery at all hours of the day.' },
    { title: 'Providing technical support', jianjie1: '1.Being your valued engineering partner to offer pre-sales and after-sales professional support.', jianjie2: '2.with in-depth knowledge on a variety of technical documents to help customer to solve real design challenges.', jianjie3: '3.Offering alternative solutions of obsolete and hard to find items.' },
    { title: 'Providing specialized testing services', jianjie1: '1.Acknowledging the basic skills and methods of components inspection, such as, appearance inspection, functional inspection, etc.', jianjie2: '2.Supporting third-party component testing services per customer request.', jianjie3: '3.Providing professional testing reports of authoritative testing institutions.' },
]

export const newImgList = [
    { src: require("../assets/construction/01.png") },
    { src: require("../assets/construction/02.png") },
    { src: require("../assets/construction/03.png") },
    { src: require("../assets/construction/04.png") },
    { src: require("../assets/construction/05.png") },
    { src: require("../assets/construction/06.png") },
    { src: require("../assets/construction/07.png") },
    { src: require("../assets/construction/08.png") },
    { src: require("../assets/construction/09.png") },
    { src: require("../assets/construction/10.png") },
    ]

export const pNewImgList = [
        [ require("../assets/construction/01.png") ],
        [ require("../assets/construction/02.png") ],
        [ require("../assets/construction/03.png") ],
        [ require("../assets/construction/04.png") ],
        [ require("../assets/construction/05.png") ],
        [ require("../assets/construction/06.png") ],
        [ require("../assets/construction/07.png") ],
        [ require("../assets/construction/08.png") ],
        [ require("../assets/construction/09.png") ],
        [ require("../assets/construction/10.png") ],
        ] 

export const mapList = {
    title:"深圳市嘉拓科技有限公司",
    lebal:"深圳市龙岗区坂田街道雅宝路1号星河WORLD B2401a",
    e:"admin@jiatuoic.com",
}

export const mapListEN = {
    title:"J&T ELECTRONICS LTD",
    lebal:"RM 1005,10/F, HO KING COMMERCIAL CENTRE 2-16 FA YUEN STREETMONGKOK KL, HONHKONG",
    e:"Cindy@jt-electronics.com",
}