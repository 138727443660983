<template>
  <div class="map_wrap">
    <div class="map_left">
      <img
        src="https://restapi.amap.com/v3/staticmap?location=114.061504,22.604035&zoom=15&size=800*500&markers=mid,,A:114.061504,22.604035&key=b934bea876b775f512d937a9131de8ea"
      />
    </div>
    <div class="synopsis_right pl_45">
      <div class="business-content-wrap">
        <div class="section-title">
          <div class="title" style="font-size: 36px;text-align: left;">{{ mapList.title }}</div>
        </div>
        <p style="margin-bottom: 16px;font-size: 20px">
          <i class="el-icon-position"></i>{{ mapList.lebal }}
        </p>
        <p style="margin-bottom: 16px;font-size: 20px">
          <i class="el-icon-message"></i>{{ mapList.e }}
        </p>
        <p style="margin-bottom: 16px;font-size: 20px">
          <i class="el-icon-phone-outline"></i>0755-23824812
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
    mapList: {
      type: Object,
    },
  },
    mounted() {},
};
</script>

<style scoped>
.map_wrap {
  padding: 30px 100px;
  display: flex;
  justify-content: space-between;
}
.map_left {
  width: 50%;
}
.map_left img {
  width: 100%;
  border-radius: 20px;
}
.synopsis_right {
  width: 50%;
  text-align: left;
  box-sizing: border-box;
  display: flex;
}
.business-content-wrap {
  margin: auto 0;
}
.business-content-wrap .section-title {
  margin-bottom: 30px;
}
.business-content-wrap .section-title .title {
  font-size: 40px;
  margin-bottom: 0;
}
.el-icon-position,.el-icon-message,.el-icon-phone-outline{
  margin-right: 10px;
}
</style>