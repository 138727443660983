<template>
  <div class="news_img mb_7">
    <el-carousel :interval="8000" type="card" height="400px">
      <el-carousel-item v-for="(item, index) in data.newImgList" :key="index">
        <el-image
          style="width: 100%; height: 100%"
          :src="item.src"
          fit="contain"
          :preview-src-list="data.pNewImgList[index]"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import * as data from "../utils/data.js";
export default {
  data() {
    return {
      data: data,
    };
  },
};
</script>

<style scoped>
.news_img {
  margin-top: 30px;
}
</style>