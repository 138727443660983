<template>
  <div class="home_wrap">
    <el-container>
      <el-header height="80px">
        <div class="header">
          <div class="header_menu">
            <div class="logo">
              <div class="logo_img">
                <img src="../assets/img/logo.png" alt="" />
              </div>
              <div class="logo_title">
                <p>
                  嘉拓科技
                  <br />
                  J&T
                </p>
              </div>
            </div>
            <div class="header_main">
              <Menu :active="activeIndex" :menuList="data.menuListEN" />
            </div>
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh">{{ Chinese }}</el-dropdown-item>
                <el-dropdown-item command="en">{{ English }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <div class="mb_7">
          <img src="../assets/img/WechatIMG265.jpeg" alt="">
      </div>  
      <div class="product_wrap">
        <div class="wrap">
        <div class="section_wrap">
        <div class="section-title" style="color:#fff">{{ productTitle }}</div>
    </div>
        <div class="product_xq">
          <div class="product_xqwrap" v-for="(item,index) in productList" :key="index">
            <div class="team-item">
              <div class="team-thumb">
                <img :src="item.src" alt="" />
                <span class="thumb-bg"></span>
              </div>
              <!-- <div class="team-content">
                <h5>名称</h5>
                <p>产品简介</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="Advantage_distribution" ref="distribution">
        <Brand :title="brandTitle"  :iconShow="iconShow" :hetongTitle="hetongTitle"/>
      </div>
      <el-footer>
        <Footer :footerObj="data.footerObjEN" :menuList="data.menuListEN" />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Brand from "../components/Brand.vue";
import * as data from "../utils/data.js";

export default {
  components: {
    Menu,
    Footer,
    Brand,
  },
  data() {
    return {
      data: data,
      activeIndex: "1",
      language: "Language",
      Chinese: "Chinese",
      English: "English",
      brandTitle: "Line card",
      hetongTitle: "Quality Assurance",
      productTitle: 'Product details',
      iconShow: false,
      productList: [
        { src: require('../assets/img/WechatIMG272.png')},
        { src: require('../assets/img/WechatIMG273.png')},
        { src: require('../assets/img/WechatIMG274.png')},
        { src: require('../assets/img/WechatIMG275.png')},
      ],
    };
  },
  methods: {
    handleCommand(command) {
      if (command == "zh") {
        this.$router.push({
          name: data.menuList[this.activeIndex].name,
        });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.product_wrap {
  background-image: url(../assets/img/soft_features_bg.jpg);
  background-size: cover;
  background-position: top center;
}
.product_xq{
  padding: 30px 100px;
  display: flex;
  justify-content: space-between;
}
.product_xqwrap{
  width: 20%;
}
.team-thumb img{
  width: 100%;
}
.el-dropdown {
  color: #333;
}
.el-footer {
  padding: 0;
  color: rgb(184, 184, 184);
}
.team-item {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  z-index: 1;
}
.team-item .team-thumb {
  margin-bottom: 55px;
}
.team-item:hover .team-content > span {
  color: #ff4b36;
}
.team-item:hover .team-thumb > .thumb-bg {
  transform: translateX(-50%) rotate(65deg);
}
.team-item:hover .team-thumb img {
  border-color: #ff4b36;
}
.team-thumb > .thumb-bg {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-shadow: 0px 9px 6.51px 0.49px rgba(17, 7, 128, 0.14);
  background: #fff;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%) rotate(0deg);
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  z-index: -1;
}
.team-thumb > .thumb-bg::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 16%;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
}
</style>
