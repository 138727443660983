<template>
  <div>
    <div class="wrap">
      <div class="section_wrap">
        <div class="section-title" @click="handleCommand">{{ title }}</div>
        <div>
          <i class="el-icon-right el-icon-right_24" @click="handleCommand"></i>
        </div>
      </div>
      <div
        class="col"
        ref="section"
        :class="{ animate__fadeInUp: header_Animate }"
      >
        <div
          class="soft-services-item"
          v-for="(item, index) in service"
          :key="index"
        >
          <div class="soft-services-icon">
            <i :class="item.icon"></i>
          </div>
          <div class="soft-services-content">
            <p style="margin-bottom: 0">{{ item.lebal }}</p>
            <p>{{ item.lebals }}</p>
          </div>
          <div class="soft-services-icons" @click="handleCommand">
            <i class="el-icon-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="Advantage_distribution" ref="distribution">
      <div
        v-if="showDistribution"
        :class="{ animate__fadeInUp: showDistribution }"
      >
        <Brand :title="brandTitle" :iconShow="iconShow" />
      </div>
    </div>
    <div class="new_wrap" ref="newWrap">
      <div v-if="showNewWrap">
        <News :title="newTitle" />
      </div>
    </div>
  </div>
</template>

<script>
import Brand from "./Brand.vue";
import News from "./News.vue";
import { isElementNotInViewport } from "../utils/utils.js";

export default {
  components: {
    Brand,
    News,
  },
  props: {
    service: {
      type: Array,
    },
    title: {
      type: String,
      required: "服务项目",
    },
  },
  data() {
    return {
      header_Animate: false,
      showDistribution: false,
      brandTitle: "优势分销",
      newTitle: "新闻中心",
      showNewWrap: false,
      iconShow: true,
    };
  },
  mounted() {
    if (this.title != "服务项目") {
      this.brandTitle = "Line card";
      this.newTitle = "News";
    }
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    handleCommand() {
      if (this.title == "服务项目") {
        this.$router.push({
          name: "Services",
        });
      } else {
        this.$router.push({
          name: "en/Services",
        });
      }
    },
    // 滚动事件
    scrollToTop() {
      !isElementNotInViewport(this.$refs.section)
        ? (this.header_Animate = true)
        : "";
      !isElementNotInViewport(this.$refs.distribution)
        ? (this.showDistribution = true)
        : "";
      !isElementNotInViewport(this.$refs.newWrap)
        ? (this.showNewWrap = true)
        : "";
    },
  },
};
</script>

<style scoped>
.wrap {
  background-image: url(../assets/img/banner_bg.jpg);
  background-size: cover;
  background-position: top center;
}
.col {
  display: flex;
  justify-content: space-between;
  padding: 30px 100px;
}
.soft-services-item {
  background-size: cover;
  background-position: top center;
  min-width: 15%;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 40px 24px;
  position: relative;
  /* margin-bottom: 30px; */
  z-index: 1;
  background: transparent;
  border-radius: 8px;
}
.soft-services-item:hover {
  background: rgba(40, 68, 153, 0.2);
  border-radius: 8px;
}
.soft-services-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 9px 6.51px 0.49px rgba(54, 40, 207, 0.14);
  border-radius: 30px 15px 15px 15px;
  opacity: 0;
  z-index: -1;
}
.soft-services-content {
  width: 100%;
}
.soft-services-content p {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.soft-services-icon i {
  font-size: 80px;
}
.el-icon-right {
  font-size: 24px;
}
.soft-services-icons {
  width: 24px;
  height: 24px;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: pointer;
}
</style>