<template>
  <div class="footer_warp">
    <div class="footer_top">
      <el-row>
        <el-col :span="8">
          <div class="">
            <div class="logo">
              <div class="logo_img">
                <img src="../assets/img/logo.png" alt="" />
              </div>
              <div class="logo_title">
                <p>
                  嘉拓科技
                  <br />
                  J&T
                </p>
              </div>
            </div>
            <div class="footer_title">
              <p>{{ footerObj.jianjie }}</p>
              <ul class="footer_ul">
                <!-- <li>深圳市福田区深南中路2070号电子科技大厦A座2106</li> -->
                <li><i class="el-icon-message"></i>admin@jiatuoic.com</li>
                <li><i class="el-icon-phone-outline"></i>0755-23824812</li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :span="8" class="margin_33">
          <div class="footer_widget">
            <h4 class="fw_title">
              {{ footerObj.title }}<span class="line"></span><span class="dots">.</span>
            </h4>
            <div>
              <ul class="fw_ul">
                <li
                  v-for="(item, index) in menuList"
                  :key="index"
                  @click="handleSelect(item)"
                ><i class="el-icon-d-arrow-right"></i>
                  {{ item.lebal }}
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="s-copyright-wrap">
      <div class="" style="padding: 10px">
        <div class="">
          <div class="">
            <div class="">
              <p style="color: #b8b8b8">
                <img
                  src="../assets/img/govbeian.png"
                  width="20px"
                  style="vertical-align: middle; border-style: none"
                  alt=""
                />
                粤公网安备<a
                  href="http://www.beian.gov.cn/"
                  style="color: #b8b8b8"
                  >粤ICP备20053897号</a
                >
              </p>
            </div>
          </div>
          <div class="">
            <div style="font-size: 12px; color: #b8b8b8">
              Copyright © 2019-{{ time }} 深圳市嘉拓科技有限公司 版权所有
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props:{
    footerObj: {
      type: Object,
    },
    menuList: {
      type: Array,
    },
  },
  data() {
    return {
      time: "",
    };
  },
  mounted() {
    this.time = new Date().getFullYear();
  },
  methods: {
    handleSelect(item) {
      if (item.name != this.$route.name) {
        this.$router.push({
          name: item.name,
        });
      }else{
        window.location.reload()
      }
    },
  },
};
</script>

<style scopeed>
.footer_top {
  height: 250px;
  padding: 40px 100px;
  background-image: url(../assets/img/footer_bg.jpg);
  font-size: 14px;
  line-height: 24px;
}
.footer_title p {
  text-align: left;
  margin: 20px 0;
}
.footer_ul li {
  text-align: left;
  line-height: 30px;
}
.margin_33{
    margin-left: 33.33333%;
}
.footer_widget {
  margin: 50px 0;
}
.fw_title {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-end;
  color: #fff;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  margin-right: 30px;
}
.line {
  display: block;
  height: 1px;
  background: #6e6889;
  flex-grow: 1;
  margin: 0 7px 5px 15px;
}
.dots {
  line-height: 1;
  color: #ff4b36;
}
.zhanwei {
  visibility:hidden
}
.fw_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fw_ul li {
  margin-bottom: 15px;
  width: 44%;
  flex: 0 0 44%;
  text-align: left;
  cursor: pointer;
}
.s-copyright-wrap {
  background: #2d2952;
  font-size: 14px;
}
.el-icon-message,.el-icon-phone-outline{
  margin-right: 8px;
}
</style>