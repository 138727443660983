<template>
  <div class="brand_wrap">
    <div class="section_wrap">
      <div class="section-title" @click="handleCommand">{{ title }}</div>
      <div>
        <i
          class="el-icon-right el-icon-right_24"
          @click="handleCommand"
          v-if="iconShow"
        ></i>
      </div>
    </div>
    <div class="changpai" v-if="title == '优势分销' || title == 'Line card'">
      <div class="changpai1" v-for="(item, index) in imgList" :key="index">
        <div>
          <img :src="item.src" alt="" />
        </div>
      </div>
    </div>
    <template v-if="hetongTitle">
      <div class="section-title" @click="handleCommand">{{ hetongTitle }}</div>
      <div class="hetong">
        <div class="hetong1" v-for="(item, index) in hetongImg" :key="index">
          <div>
            <el-image
              style="width: 100%"
              :src="item"
              :preview-src-list="hetongImg"
            >
            </el-image>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: "优势分销",
    },
    iconShow: {
      type: Boolean,
      required: true,
    },
    hetongTitle: {
      required: false,
    },
  },
  data() {
    return {
      imgList: [
        { src: require("../assets/brand/ADI.png") },
        { src: require("../assets/brand/ALTERA.png") },
        { src: require("../assets/brand/AMPHENOL.png") },
        { src: require("../assets/brand/AVAGO.png") },
        { src: require("../assets/brand/BROADCOM.png") },
        { src: require("../assets/brand/CYPRESS.png") },
        { src: require("../assets/brand/Freescale.png") },
        { src: require("../assets/brand/INFINEON.png") },
        { src: require("../assets/brand/INTEL.png") },
        { src: require("../assets/brand/INTERSIL.png") },
        { src: require("../assets/brand/IR.png") },
        { src: require("../assets/brand/LT.png") },
        { src: require("../assets/brand/MACOM.png") },
        { src: require("../assets/brand/MAXIM.png") },
        { src: require("../assets/brand/MICROCHIP.png") },
        { src: require("../assets/brand/MICRON.png") },
        { src: require("../assets/brand/MICROSEMI.png") },
        { src: require("../assets/brand/NXP.png") },
        { src: require("../assets/brand/REALTEK.png") },
        { src: require("../assets/brand/SAMSUNG.png") },
        { src: require("../assets/brand/SPANSION.png") },
        { src: require("../assets/brand/TI.png") },
        { src: require("../assets/brand/TOSHIBA.png") },
        { src: require("../assets/brand/XILINX.png") },
      ],
      hetongImg: [
        require("../assets/contract/1.jpg"),
        require("../assets/contract/2.jpg"),
        require("../assets/contract/3.jpg"),
        require("../assets/contract/4.jpg"),
        require("../assets/contract/5.jpg"),
        require("../assets/contract/6.jpg"),
        require("../assets/contract/7.jpg"),
        require("../assets/contract/8.jpg"),
        require("../assets/contract/9.jpg"),
        require("../assets/contract/10.jpg"),
        require("../assets/contract/11.jpg"),
        require("../assets/contract/12.jpg"),
      ],
    };
  },
  methods: {
    handleCommand() {
      if (this.iconShow) {
        if (this.title == "优势分销") {
          this.$router.push({
            name: "Products",
          });
        } else {
          this.$router.push({
            name: "en/Products",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.changpai,
.hetong {
  padding: 30px 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.changpai > div,
.hetong > div {
  margin-bottom: 15px;
  padding: 0;
}
.changpai1,
.hetong1 {
  width: 15%;
}
.changpai div img {
  width: 100%;
  transform: scale(1);
  display: flex;
}
.changpai1 > div {
  width: 100%;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
}
.changpai1:hover img {
  /* width: 120%; */
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
</style>