<template>
  <div class="home_wrap">
    <el-container>
      <el-header height="80px">
        <div class="header">
          <div class="header_menu">
            <div class="logo">
              <div class="logo_img">
                <img src="../assets/img/logo.png" alt="" />
              </div>
              <div class="logo_title">
                <p>
                  嘉拓科技
                  <br />
                  J&T
                </p>
              </div>
            </div>
            <div class="header_main">
              <Menu :active="activeIndex" :menuList="data.menuList"/>
            </div>
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh">{{ Chinese }}</el-dropdown-item>
                <el-dropdown-item command="en">{{ English }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <Carousel/>
      <Synopsis :mainObj="data.mainObj"/>
      <Services :service="data.ServicesList" :title="servicesTitle"/>
      <el-footer> 
        <Footer :footerObj="data.footerObj" :menuList="data.menuList"/>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Carousel from "../components/Carousel.vue";
import Synopsis from "../components/Synopsis.vue";
import Services from "../components/Services.vue";
import * as data from "../utils/data.js";

export default {
  components: {
    Menu,
    Footer,
    Carousel,
    Synopsis,
    Services,
  },
  data() {
    return {
      data: data,
      activeIndex: "0",
      language: "语言",
      Chinese: "中文",
      English: "英文",
      servicesTitle: "服务项目",
    };
  },
  methods: {
    handleCommand(command) {
      if (command == "en") {
        this.$router.push({
          name: data.menuListEN[this.activeIndex].name,
        });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.el-dropdown {
  color: #333;
}
.el-footer{
  padding: 0;
  color: rgb(184, 184, 184);
}
</style>
