<template>
    <div class="synopsis_wrap_s">
    <div class="synopsis_main" ref="synopsis_wrap_s">
      <div class="synopsis_left" :class="{ animate__fadeInLeft: header_Animate }">
        <img src="../assets/img/WechatIMG246.jpeg" alt="" />
      </div>
      <div class="synopsis_right pl_45" :class="{ animate__fadeInRight: header_Animate }">
        <div class="business-content-wrap">
          <div class="section-title">
            <div class="title">{{ animate[0].title }}</div>
          </div>
          <p style="margin-bottom: 16px">
              {{ animate[0].jianjie1 }}
          </p>
          <p style="margin-bottom: 16px">
                {{ animate[0].jianjie2 }}
          </p>
          <p>
                {{ animate[0].jianjie3 }}
          </p>
        </div>
      </div>
    </div>
    <div class="synopsis_main" ref="synopsis_wrap_s1">
      <div class="synopsis_right pl_45" :class="{ animate__fadeInLeft: header_Animate1 }">
        <div class="business-content-wrap">
          <div class="section-title">
            <div class="title">{{ animate[1].title }}</div>
          </div>
          <p style="margin-bottom: 16px">
              {{ animate[1].jianjie1 }}
          </p>
          <p style="margin-bottom: 16px">
                {{ animate[1].jianjie2 }}
          </p>
          <p>
                {{ animate[1].jianjie3 }}
          </p>
        </div>
      </div>
      <div class="synopsis_left" :class="{ animate__fadeInRight: header_Animate1 }">
        <img src="../assets/img/WechatIMG247.jpeg" alt="" />
      </div>
    </div>
    <div class="synopsis_main" ref="synopsis_wrap_s2">
      <div class="synopsis_left" :class="{ animate__fadeInLeft: header_Animate2 }">
        <img src="../assets/img/WechatIMG248.jpeg" alt="" />
      </div>
      <div class="synopsis_right pl_45" :class="{ animate__fadeInRight: header_Animate2 }">
        <div class="business-content-wrap">
          <div class="section-title">
            <div class="title">{{ animate[2].title }}</div>
          </div>
          <p style="margin-bottom: 16px">
              {{ animate[2].jianjie1 }}
          </p>
          <p style="margin-bottom: 16px">
                {{ animate[2].jianjie2 }}
          </p>
          <p>
                {{ animate[2].jianjie3 }}
          </p>
        </div>
      </div>
    </div>
    <div class="synopsis_main" ref="synopsis_wrap_s3">
      <div class="synopsis_right pl_45" :class="{ animate__fadeInLeft: header_Animate3 }">
        <div class="business-content-wrap">
          <div class="section-title">
            <div class="title">{{ animate[3].title }}</div>
          </div>
          <p style="margin-bottom: 16px">
              {{ animate[3].jianjie1 }}
          </p>
          <p style="margin-bottom: 16px">
                {{ animate[3].jianjie2 }}
          </p>
          <p>
                {{ animate[3].jianjie3 }}
          </p>
        </div>
      </div>
      <div class="synopsis_left" :class="{ animate__fadeInRight: header_Animate3 }">
        <img src="../assets/img/WechatIMG249.jpeg" alt="" />
      </div>
    </div>
    <div class="synopsis_main" ref="synopsis_wrap_s4">
      <div class="synopsis_left" :class="{ animate__fadeInLeft: header_Animate4 }">
        <img src="../assets/img/WechatIMG250.jpeg" alt="" />
      </div>
      <div class="synopsis_right pl_45" :class="{ animate__fadeInRight: header_Animate4 }">
        <div class="business-content-wrap">
          <div class="section-title">
            <div class="title">{{ animate[4].title }}</div>
          </div>
          <p style="margin-bottom: 16px">
              {{ animate[4].jianjie1 }}
          </p>
          <p style="margin-bottom: 16px">
                {{ animate[4].jianjie2 }}
          </p>
          <p>
                {{ animate[4].jianjie3 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { isElementNotInViewport } from "../utils/utils.js";

export default {
  props:{
    animate:{
      type: Array,
    }
  },
  data() {
    return {
      header_Animate: false,
      header_Animate1: false,
      header_Animate2: false,
      header_Animate3: false,
      header_Animate4: false,
    };
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      !isElementNotInViewport(this.$refs.synopsis_wrap_s)
        ? (this.header_Animate = true)
        : "";
      !isElementNotInViewport(this.$refs.synopsis_wrap_s1)
        ? (this.header_Animate1 = true)
        : "";
      !isElementNotInViewport(this.$refs.synopsis_wrap_s2)
        ? (this.header_Animate2 = true)
        : "";
      !isElementNotInViewport(this.$refs.synopsis_wrap_s3)
        ? (this.header_Animate3 = true)
        : "";
      !isElementNotInViewport(this.$refs.synopsis_wrap_s4)
        ? (this.header_Animate4 = true)
        : "";
    },
  },
};
</script>

<style scoped>
.synopsis_main {
  background-image: url(../assets/img/banner_bg.jpg);
  background-size: cover;
  background-position: top center;
  padding: 80px 100px 30px;
  display: flex;
  justify-content: space-between;
}
.synopsis_left {
  width: 50%;
  display: flex;
}
.synopsis_left img {
  width: 100%;
  border-radius: 20px;
  margin: auto;
}
.synopsis_right {
  width: 50%;
  box-sizing: border-box;
  display: flex;
}
.business-content-wrap{
    margin: auto;
}
.business-content-wrap .section-title {
    margin-bottom: 30px;
}
.business-content-wrap .section-title .title {
    font-size: 40px;
    margin-bottom: 0;
}
</style>
