import Vue from "vue"
import VueRouter from "vue-router";
const Home = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/Home.vue')
const About = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/About.vue')
const News = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/News.vue')
const Products = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/Product.vue')
const Contact = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/Contact.vue')
const Services = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/Service.vue')
const HomeEN = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/HomeEN.vue')
const AboutEN = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/AboutEN.vue')
const NewsEN = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/NewsEN.vue')
const ProductsEN = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/ProductEN.vue')
const ContactEN = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/ContactEN.vue')
const ServicesEN = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../views/ServiceEN.vue')
const notfount = () => import(/* webpackChunkName: 'ImportFuncDemo' */ '../components/404.vue')
// import Home from "../views/Home.vue";
// import About from "../views/About.vue";
// import News from "../views/News.vue";
// import Products from "../views/Products.vue";
// import Contact from "../views/Contact.vue";
// import Services from "../views/Services.vue";
// import HomeEN from "../views/HomeEN.vue";
// import AboutEN from "../views/AboutEN.vue";
// import NewsEN from "../views/NewsEN.vue";
// import ProductsEN from "../views/ProductsEN.vue";
// import ContactEN from "../views/ContactEN.vue";
// import ServicesEN from "../views/ServicesEN.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/news",
        name: "News",
        component: News,
    },
    {
        path: "/products",
        name: "Products",
        component: Products,
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/services",
        name: "Services",
        component: Services,
    },
    {
        path: "/en",
        name: "en/Home",
        component: HomeEN,
    },
    {
        path: "/en/about",
        name: "en/About",
        component: AboutEN,
    },
    {
        path: "/en/news",
        name: "en/News",
        component: NewsEN,
    },
    {
        path: "/en/products",
        name: "en/Products",
        component: ProductsEN,
    },
    {
        path: "/en/contact",
        name: "en/Contact",
        component: ContactEN,
    },
    {
        path: "/en/services",
        name: "en/Services",
        component: ServicesEN,
    },
    {
        path: '*',
        name: 'notfount',
        component: notfount,
    }
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router;