<template>
  <div class="synopsis_wrap">
    <div class="synopsis_main" ref="synopsis_wrap">
      <div class="synopsis_left" :class="{ animate__fadeInLeft: header_Animate }">
        <img src="../assets/img/gongsi.jpeg" alt="" />
      </div>
      <div class="synopsis_right pl_45" :class="{ animate__fadeInRight: header_Animate }">
        <div class="business-content-wrap">
          <div class="section-title">
            <div class="title">{{ mainObj.title }}</div>
          </div>
          <p style="margin-bottom: 10px">
              {{ mainObj.jianjie1 }}
          </p>
          <p style="margin-bottom: 10px">
                {{ mainObj.jianjie2 }}
          </p>
          <p style="margin-bottom: 10px">
              {{ mainObj.jianjie3 }}
          </p>
          <p>
                {{ mainObj.jianjie4 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../utils/utils.js";

export default {
    props:{
        mainObj: {
        type: Object,
        },
  },
  data(){
      return{
          header_Animate: false,
      }
  },
  mounted(){
       // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
    beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener("scroll", this.scrollToTop);
    next();
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      !isElementNotInViewport(this.$refs.synopsis_wrap)
        ? (this.header_Animate = true)
        : "";
    },
  },
};
</script>

<style scoped>
.synopsis_wrap {
  background-image: url(../assets/img/business_bg.png);
  background-size: cover;
  background-position: top center;
}
.synopsis_main {
  padding: 80px 100px 30px;
  display: flex;
  justify-content: space-between;
}
.synopsis_left {
  width: 50%;
  display: flex;
}
.synopsis_left img {
  width: 100%;
  border-radius: 20px;
  margin: auto;
}
.synopsis_right {
  width: 50%;
  box-sizing: border-box;
  display: flex;
}
.business-content-wrap{
    margin: auto;
}
.business-content-wrap .section-title {
    margin-bottom: 8px;
}
.business-content-wrap .section-title .title {
    font-size: 40px;
    margin-bottom: 0;
}
.business-content-wrap > p {
    margin-bottom: 0;
    text-indent: 2em;
}
</style>